import * as _os2 from "os";
var _os = _os2;
try {
  if ("default" in _os2) _os = _os2.default;
} catch (e) {}
var exports = {};
const {
  EOL
} = _os;
const getFirstRegexpMatchOrDefault = (text, regexp, defaultValue) => {
  regexp.lastIndex = 0; // https://stackoverflow.com/a/11477448/4536543
  let match = regexp.exec(text);
  if (match !== null) {
    return match[1];
  } else {
    return defaultValue;
  }
};
const DEFAULT_INDENT = "  ";
const INDENT_REGEXP = /^([ \t]+)[^\s]/m;
exports.detectIndent = text => getFirstRegexpMatchOrDefault(text, INDENT_REGEXP, DEFAULT_INDENT);
exports.DEFAULT_INDENT = DEFAULT_INDENT;
const DEFAULT_EOL = EOL;
const EOL_REGEXP = /(\r\n|\n|\r)/g;
exports.detectEOL = text => getFirstRegexpMatchOrDefault(text, EOL_REGEXP, DEFAULT_EOL);
exports.DEFAULT_EOL = DEFAULT_EOL;
export default exports;